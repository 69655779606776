import clsx from "clsx";
import {ElementType, HTMLAttributes} from "react";

export default function Section({as = 'section', className = '', compact = false, padding = true, children}: HTMLAttributes<HTMLElement> & { as?: ElementType, compact?: boolean, padding?: boolean }) {

    const Component = as;
    const paddingClassName: string = compact ? "px-2" : "px-7"

    return (
        <Component className={clsx(padding && paddingClassName, className)}>
            {children}
        </Component>
    );
}
